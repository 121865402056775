
import weekly202120211220211227pulsarweekly from '../views/weekly/2021/2021-12/2021-12-27-pulsar-weekly'

import weekly202120211220211220pulsarweekly from '../views/weekly/2021/2021-12/2021-12-20-pulsar-weekly'

import weekly202120211220211213pulsarweekly from '../views/weekly/2021/2021-12/2021-12-13-pulsar-weekly'

import weekly202120211220211206pulsarweekly from '../views/weekly/2021/2021-12/2021-12-06-pulsar-weekly'

import weekly202120211120211129pulsarweekly from '../views/weekly/2021/2021-11/2021-11-29-pulsar-weekly'

import weekly202120211120211122pulsarweekly from '../views/weekly/2021/2021-11/2021-11-22-pulsar-weekly'

import weekly202120211120211115pulsarweekly from '../views/weekly/2021/2021-11/2021-11-15-pulsar-weekly'

import weekly202120211120211108pulsarweekly from '../views/weekly/2021/2021-11/2021-11-08-pulsar-weekly'

import weekly202120211020211101pulsarweekly from '../views/weekly/2021/2021-10/2021-11-01-pulsar-weekly'

import weekly202120210920211004pulsarweekly from '../views/weekly/2021/2021-09/2021-10-04-pulsar-weekly'

import weekly202120210920210927pulsarweekly from '../views/weekly/2021/2021-09/2021-09-27-pulsar-weekly'

import weekly202120210920210920pulsarweekly from '../views/weekly/2021/2021-09/2021-09-20-pulsar-weekly'

import weekly202120210920210913pulsarweekly from '../views/weekly/2021/2021-09/2021-09-13-pulsar-weekly'

import weekly202120210920210906pulsarweekly from '../views/weekly/2021/2021-09/2021-09-06-pulsar-weekly'

import weekly202120210820210830pulsarweekly from '../views/weekly/2021/2021-08/2021-08-30-pulsar-weekly'

import weekly202120210820210823pulsarweekly from '../views/weekly/2021/2021-08/2021-08-23-pulsar-weekly'

import weekly202120210820210816pulsarweekly from '../views/weekly/2021/2021-08/2021-08-16-pulsar-weekly'

import weekly202120210820210809pulsarweekly from '../views/weekly/2021/2021-08/2021-08-09-pulsar-weekly'

import weekly202120210820210802pulsarweekly from '../views/weekly/2021/2021-08/2021-08-02-pulsar-weekly'

import weekly202120210720210726pulsarweekly from '../views/weekly/2021/2021-07/2021-07-26-pulsar-weekly'

import weekly202120210720210719pulsarweekly from '../views/weekly/2021/2021-07/2021-07-19-pulsar-weekly'

import weekly202120210720210712pulsarweekly from '../views/weekly/2021/2021-07/2021-07-12-pulsar-weekly'

import weekly202120210720210705pulsarweekly from '../views/weekly/2021/2021-07/2021-07-05-pulsar-weekly'

import weekly202120210620210628pulsarweekly from '../views/weekly/2021/2021-06/2021-06-28-pulsar-weekly'

import weekly202120210620210621pulsarweekly from '../views/weekly/2021/2021-06/2021-06-21-pulsar-weekly'

import weekly202120210620210616pulsarweekly from '../views/weekly/2021/2021-06/2021-06-16-pulsar-weekly'

import weekly202120210620210607pulsarweekly from '../views/weekly/2021/2021-06/2021-06-07-pulsar-weekly'

import weekly202120210520210531pulsarweekly from '../views/weekly/2021/2021-05/2021-05-31-pulsar-weekly'

import weekly202120210520210524pulsarweekly from '../views/weekly/2021/2021-05/2021-05-24-pulsar-weekly'

import weekly202120210520210517pulsarweekly from '../views/weekly/2021/2021-05/2021-05-17-pulsar-weekly'

import weekly202120210520210510pulsarweekly from '../views/weekly/2021/2021-05/2021-05-10-pulsar-weekly'

import weekly202120210520210506pulsarweekly from '../views/weekly/2021/2021-05/2021-05-06-pulsar-weekly'

import weekly202120210420210426pulsarweekly from '../views/weekly/2021/2021-04/2021-04-26-pulsar-weekly'

import weekly202120210420210419pulsarweekly from '../views/weekly/2021/2021-04/2021-04-19-pulsar-weekly'

import weekly202120210420210412pulsarweekly from '../views/weekly/2021/2021-04/2021-04-12-pulsar-weekly'

import weekly202120210420210405pulsarweekly from '../views/weekly/2021/2021-04/2021-04-05-pulsar-weekly'

import weekly202120210320210329pulsarweekly from '../views/weekly/2021/2021-03/2021-03-29-pulsar-weekly'

import weekly202120210320210322pulsarweekly from '../views/weekly/2021/2021-03/2021-03-22-pulsar-weekly'

import weekly202120210320210315pulsarweekly from '../views/weekly/2021/2021-03/2021-03-15-pulsar-weekly'

import weekly202120210320210308pulsarweekly from '../views/weekly/2021/2021-03/2021-03-08-pulsar-weekly'

import weekly202120210320210301pulsarweekly from '../views/weekly/2021/2021-03/2021-03-01-pulsar-weekly'

import weekly202120210220210222pulsarweekly from '../views/weekly/2021/2021-02/2021-02-22-pulsar-weekly'

import weekly202120210220210215pulsarweekly from '../views/weekly/2021/2021-02/2021-02-15-pulsar-weekly'

import weekly202120210220210208pulsarweekly from '../views/weekly/2021/2021-02/2021-02-08-pulsar-weekly'

import weekly202120210220210201pulsarweekly from '../views/weekly/2021/2021-02/2021-02-01-pulsar-weekly'

import weekly202120210120210125pulsarweekly from '../views/weekly/2021/2021-01/2021-01-25-pulsar-weekly'

import weekly202120210120210118pulsarweekly from '../views/weekly/2021/2021-01/2021-01-18-pulsar-weekly'

import weekly202120210120210111pulsarweekly from '../views/weekly/2021/2021-01/2021-01-11-pulsar-weekly'

import weekly202120210120210104pulsarweekly from '../views/weekly/2021/2021-01/2021-01-04-pulsar-weekly'

import weekly202220220320220314pulsarweekly from '../views/weekly/2022/2022-03/2022-03-14-pulsar-weekly'

import weekly202220220320220307pulsarweekly from '../views/weekly/2022/2022-03/2022-03-07-pulsar-weekly'

import weekly202220220220220228pulsarweekly from '../views/weekly/2022/2022-02/2022-02-28-pulsar-weekly'

import weekly202220220220220221pulsarweekly from '../views/weekly/2022/2022-02/2022-02-21-pulsar-weekly'

import weekly202220220220220214pulsarweekly from '../views/weekly/2022/2022-02/2022-02-14-pulsar-weekly'

import weekly202220220220220207pulsarweekly from '../views/weekly/2022/2022-02/2022-02-07-pulsar-weekly'

import weekly202220220120220131pulsarweekly from '../views/weekly/2022/2022-01/2022-01-31-pulsar-weekly'

import weekly202220220120220124pulsarweekly from '../views/weekly/2022/2022-01/2022-01-24-pulsar-weekly'

import weekly202220220120220117pulsarweekly from '../views/weekly/2022/2022-01/2022-01-17-pulsar-weekly'

import weekly202220220120220110pulsarweekly from '../views/weekly/2022/2022-01/2022-01-10-pulsar-weekly'

import weekly202220220120220103pulsarweekly from '../views/weekly/2022/2022-01/2022-01-03-pulsar-weekly'

import weekly202020201220201228pulsarweekly from '../views/weekly/2020/2020-12/2020-12-28-pulsar-weekly'

import weekly202020201220201218pulsarweekly from '../views/weekly/2020/2020-12/2020-12-18-pulsar-weekly'

import weekly202020201220201211pulsarweekly from '../views/weekly/2020/2020-12/2020-12-11-pulsar-weekly'

import weekly202020201220201204pulsarweekly from '../views/weekly/2020/2020-12/2020-12-04-pulsar-weekly'

import weekly202020201120201127pulsarweekly from '../views/weekly/2020/2020-11/2020-11-27-pulsar-weekly'

import weekly202020201120201120pulsarweekly from '../views/weekly/2020/2020-11/2020-11-20-pulsar-weekly'

import weekly202020201120201113pulsarweekly from '../views/weekly/2020/2020-11/2020-11-13-pulsar-weekly'

import weekly202020201120201106pulsarweekly from '../views/weekly/2020/2020-11/2020-11-06-pulsar-weekly'

import weekly202020201020201030pulsarweekly from '../views/weekly/2020/2020-10/2020-10-30-pulsar-weekly'

import weekly202020201020201023pulsarweekly from '../views/weekly/2020/2020-10/2020-10-23-pulsar-weekly'

import weekly202020201020201016pulsarweekly from '../views/weekly/2020/2020-10/2020-10-16-pulsar-weekly'

import weekly202020201020201009pulsarweekly from '../views/weekly/2020/2020-10/2020-10-09-pulsar-weekly'

import weekly202020200920200925pulsarweekly from '../views/weekly/2020/2020-09/2020-09-25-pulsar-weekly'

import weekly202020200920200918pulsarweekly from '../views/weekly/2020/2020-09/2020-09-18-pulsar-weekly'

import weekly202020200920200911pulsarweekly from '../views/weekly/2020/2020-09/2020-09-11-pulsar-weekly'

import weekly202020200920200904pulsarweekly from '../views/weekly/2020/2020-09/2020-09-04-pulsar-weekly'

import weekly202020200820200828pulsarweekly from '../views/weekly/2020/2020-08/2020-08-28-pulsar-weekly'

import weekly202020200820200821pulsarweekly from '../views/weekly/2020/2020-08/2020-08-21-pulsar-weekly'

import weekly202020200820200814pulsarweekly from '../views/weekly/2020/2020-08/2020-08-14-pulsar-weekly'

import weekly202020200820200807pulsarweekly from '../views/weekly/2020/2020-08/2020-08-07-pulsar-weekly'

import weekly202020200720200731pulsarweekly from '../views/weekly/2020/2020-07/2020-07-31-pulsar-weekly'

import weekly202020200720200724pulsarweekly from '../views/weekly/2020/2020-07/2020-07-24-pulsar-weekly'

import weekly202020200720200717pulsarweekly from '../views/weekly/2020/2020-07/2020-07-17-pulsar-weekly'

import weekly202020200720200710pulsarweekly from '../views/weekly/2020/2020-07/2020-07-10-pulsar-weekly'

import weekly202020200720200703pulsarweekly from '../views/weekly/2020/2020-07/2020-07-03-pulsar-weekly'

import weekly202020200620200626pulsarweekly from '../views/weekly/2020/2020-06/2020-06-26-pulsar-weekly'

import weekly202020200420200417pulsarweekly from '../views/weekly/2020/2020-04/2020-04-17-pulsar-weekly'

import weekly202020200420200403pulsarweekly from '../views/weekly/2020/2020-04/2020-04-03-pulsar-weekly'

import weekly202020200320200306pulsarweekly from '../views/weekly/2020/2020-03/2020-03-06-pulsar-weekly'

import weekly202020200220200228pulsarweekly from '../views/weekly/2020/2020-02/2020-02-28-pulsar-weekly'

import weekly202020200220200214pulsarweekly from '../views/weekly/2020/2020-02/2020-02-14-pulsar-weekly'

import weekly202020200120200131pulsarweekly from '../views/weekly/2020/2020-01/2020-01-31-pulsar-weekly'

import weekly202020200120200117pulsarweekly from '../views/weekly/2020/2020-01/2020-01-17-pulsar-weekly'

import weekly202020200120200110pulsarweekly from '../views/weekly/2020/2020-01/2020-01-10-pulsar-weekly'

import weekly202020200120200103pulsarweekly from '../views/weekly/2020/2020-01/2020-01-03-pulsar-weekly'

import weekly201920191220191220pulsarweekly from '../views/weekly/2019/2019-12/2019-12-20-pulsar-weekly'

import weekly201920191220191206pulsarweekly from '../views/weekly/2019/2019-12/2019-12-06-pulsar-weekly'

import weekly201920191120191129pulsarweekly from '../views/weekly/2019/2019-11/2019-11-29-pulsar-weekly'

import weekly201920191120191122pulsarweekly from '../views/weekly/2019/2019-11/2019-11-22-pulsar-weekly'

import weekly201920191120191116pulsarweekly from '../views/weekly/2019/2019-11/2019-11-16-pulsar-weekly'

import weekly201920191120191108pulsarweekly from '../views/weekly/2019/2019-11/2019-11-08-pulsar-weekly'

import weekly201920191120191101pulsarweekly from '../views/weekly/2019/2019-11/2019-11-01-pulsar-weekly'

import weekly201920191020191025pulsarweekly from '../views/weekly/2019/2019-10/2019-10-25-pulsar-weekly'

import weekly201920191020191018pulsarweekly from '../views/weekly/2019/2019-10/2019-10-18-pulsar-weekly'

const routes = [
    
    {
        path: '/weekly/2021/2021-12/2021-12-27-pulsar-weekly',
        name: '2021-2021-12-2021-12-27-pulsar-weekly',
        component: weekly202120211220211227pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-12/2021-12-20-pulsar-weekly',
        name: '2021-2021-12-2021-12-20-pulsar-weekly',
        component: weekly202120211220211220pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-12/2021-12-13-pulsar-weekly',
        name: '2021-2021-12-2021-12-13-pulsar-weekly',
        component: weekly202120211220211213pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-12/2021-12-06-pulsar-weekly',
        name: '2021-2021-12-2021-12-06-pulsar-weekly',
        component: weekly202120211220211206pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-11/2021-11-29-pulsar-weekly',
        name: '2021-2021-11-2021-11-29-pulsar-weekly',
        component: weekly202120211120211129pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-11/2021-11-22-pulsar-weekly',
        name: '2021-2021-11-2021-11-22-pulsar-weekly',
        component: weekly202120211120211122pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-11/2021-11-15-pulsar-weekly',
        name: '2021-2021-11-2021-11-15-pulsar-weekly',
        component: weekly202120211120211115pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-11/2021-11-08-pulsar-weekly',
        name: '2021-2021-11-2021-11-08-pulsar-weekly',
        component: weekly202120211120211108pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-10/2021-11-01-pulsar-weekly',
        name: '2021-2021-10-2021-11-01-pulsar-weekly',
        component: weekly202120211020211101pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-09/2021-10-04-pulsar-weekly',
        name: '2021-2021-09-2021-10-04-pulsar-weekly',
        component: weekly202120210920211004pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-09/2021-09-27-pulsar-weekly',
        name: '2021-2021-09-2021-09-27-pulsar-weekly',
        component: weekly202120210920210927pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-09/2021-09-20-pulsar-weekly',
        name: '2021-2021-09-2021-09-20-pulsar-weekly',
        component: weekly202120210920210920pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-09/2021-09-13-pulsar-weekly',
        name: '2021-2021-09-2021-09-13-pulsar-weekly',
        component: weekly202120210920210913pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-09/2021-09-06-pulsar-weekly',
        name: '2021-2021-09-2021-09-06-pulsar-weekly',
        component: weekly202120210920210906pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-08/2021-08-30-pulsar-weekly',
        name: '2021-2021-08-2021-08-30-pulsar-weekly',
        component: weekly202120210820210830pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-08/2021-08-23-pulsar-weekly',
        name: '2021-2021-08-2021-08-23-pulsar-weekly',
        component: weekly202120210820210823pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-08/2021-08-16-pulsar-weekly',
        name: '2021-2021-08-2021-08-16-pulsar-weekly',
        component: weekly202120210820210816pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-08/2021-08-09-pulsar-weekly',
        name: '2021-2021-08-2021-08-09-pulsar-weekly',
        component: weekly202120210820210809pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-08/2021-08-02-pulsar-weekly',
        name: '2021-2021-08-2021-08-02-pulsar-weekly',
        component: weekly202120210820210802pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-07/2021-07-26-pulsar-weekly',
        name: '2021-2021-07-2021-07-26-pulsar-weekly',
        component: weekly202120210720210726pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-07/2021-07-19-pulsar-weekly',
        name: '2021-2021-07-2021-07-19-pulsar-weekly',
        component: weekly202120210720210719pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-07/2021-07-12-pulsar-weekly',
        name: '2021-2021-07-2021-07-12-pulsar-weekly',
        component: weekly202120210720210712pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-07/2021-07-05-pulsar-weekly',
        name: '2021-2021-07-2021-07-05-pulsar-weekly',
        component: weekly202120210720210705pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-06/2021-06-28-pulsar-weekly',
        name: '2021-2021-06-2021-06-28-pulsar-weekly',
        component: weekly202120210620210628pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-06/2021-06-21-pulsar-weekly',
        name: '2021-2021-06-2021-06-21-pulsar-weekly',
        component: weekly202120210620210621pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-06/2021-06-16-pulsar-weekly',
        name: '2021-2021-06-2021-06-16-pulsar-weekly',
        component: weekly202120210620210616pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-06/2021-06-07-pulsar-weekly',
        name: '2021-2021-06-2021-06-07-pulsar-weekly',
        component: weekly202120210620210607pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-05/2021-05-31-pulsar-weekly',
        name: '2021-2021-05-2021-05-31-pulsar-weekly',
        component: weekly202120210520210531pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-05/2021-05-24-pulsar-weekly',
        name: '2021-2021-05-2021-05-24-pulsar-weekly',
        component: weekly202120210520210524pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-05/2021-05-17-pulsar-weekly',
        name: '2021-2021-05-2021-05-17-pulsar-weekly',
        component: weekly202120210520210517pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-05/2021-05-10-pulsar-weekly',
        name: '2021-2021-05-2021-05-10-pulsar-weekly',
        component: weekly202120210520210510pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-05/2021-05-06-pulsar-weekly',
        name: '2021-2021-05-2021-05-06-pulsar-weekly',
        component: weekly202120210520210506pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-04/2021-04-26-pulsar-weekly',
        name: '2021-2021-04-2021-04-26-pulsar-weekly',
        component: weekly202120210420210426pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-04/2021-04-19-pulsar-weekly',
        name: '2021-2021-04-2021-04-19-pulsar-weekly',
        component: weekly202120210420210419pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-04/2021-04-12-pulsar-weekly',
        name: '2021-2021-04-2021-04-12-pulsar-weekly',
        component: weekly202120210420210412pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-04/2021-04-05-pulsar-weekly',
        name: '2021-2021-04-2021-04-05-pulsar-weekly',
        component: weekly202120210420210405pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-03/2021-03-29-pulsar-weekly',
        name: '2021-2021-03-2021-03-29-pulsar-weekly',
        component: weekly202120210320210329pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-03/2021-03-22-pulsar-weekly',
        name: '2021-2021-03-2021-03-22-pulsar-weekly',
        component: weekly202120210320210322pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-03/2021-03-15-pulsar-weekly',
        name: '2021-2021-03-2021-03-15-pulsar-weekly',
        component: weekly202120210320210315pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-03/2021-03-08-pulsar-weekly',
        name: '2021-2021-03-2021-03-08-pulsar-weekly',
        component: weekly202120210320210308pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-03/2021-03-01-pulsar-weekly',
        name: '2021-2021-03-2021-03-01-pulsar-weekly',
        component: weekly202120210320210301pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-02/2021-02-22-pulsar-weekly',
        name: '2021-2021-02-2021-02-22-pulsar-weekly',
        component: weekly202120210220210222pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-02/2021-02-15-pulsar-weekly',
        name: '2021-2021-02-2021-02-15-pulsar-weekly',
        component: weekly202120210220210215pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-02/2021-02-08-pulsar-weekly',
        name: '2021-2021-02-2021-02-08-pulsar-weekly',
        component: weekly202120210220210208pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-02/2021-02-01-pulsar-weekly',
        name: '2021-2021-02-2021-02-01-pulsar-weekly',
        component: weekly202120210220210201pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-01/2021-01-25-pulsar-weekly',
        name: '2021-2021-01-2021-01-25-pulsar-weekly',
        component: weekly202120210120210125pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-01/2021-01-18-pulsar-weekly',
        name: '2021-2021-01-2021-01-18-pulsar-weekly',
        component: weekly202120210120210118pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-01/2021-01-11-pulsar-weekly',
        name: '2021-2021-01-2021-01-11-pulsar-weekly',
        component: weekly202120210120210111pulsarweekly
    },
    
    {
        path: '/weekly/2021/2021-01/2021-01-04-pulsar-weekly',
        name: '2021-2021-01-2021-01-04-pulsar-weekly',
        component: weekly202120210120210104pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-03/2022-03-14-pulsar-weekly',
        name: '2022-2022-03-2022-03-14-pulsar-weekly',
        component: weekly202220220320220314pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-03/2022-03-07-pulsar-weekly',
        name: '2022-2022-03-2022-03-07-pulsar-weekly',
        component: weekly202220220320220307pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-02/2022-02-28-pulsar-weekly',
        name: '2022-2022-02-2022-02-28-pulsar-weekly',
        component: weekly202220220220220228pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-02/2022-02-21-pulsar-weekly',
        name: '2022-2022-02-2022-02-21-pulsar-weekly',
        component: weekly202220220220220221pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-02/2022-02-14-pulsar-weekly',
        name: '2022-2022-02-2022-02-14-pulsar-weekly',
        component: weekly202220220220220214pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-02/2022-02-07-pulsar-weekly',
        name: '2022-2022-02-2022-02-07-pulsar-weekly',
        component: weekly202220220220220207pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-01/2022-01-31-pulsar-weekly',
        name: '2022-2022-01-2022-01-31-pulsar-weekly',
        component: weekly202220220120220131pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-01/2022-01-24-pulsar-weekly',
        name: '2022-2022-01-2022-01-24-pulsar-weekly',
        component: weekly202220220120220124pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-01/2022-01-17-pulsar-weekly',
        name: '2022-2022-01-2022-01-17-pulsar-weekly',
        component: weekly202220220120220117pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-01/2022-01-10-pulsar-weekly',
        name: '2022-2022-01-2022-01-10-pulsar-weekly',
        component: weekly202220220120220110pulsarweekly
    },
    
    {
        path: '/weekly/2022/2022-01/2022-01-03-pulsar-weekly',
        name: '2022-2022-01-2022-01-03-pulsar-weekly',
        component: weekly202220220120220103pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-12/2020-12-28-pulsar-weekly',
        name: '2020-2020-12-2020-12-28-pulsar-weekly',
        component: weekly202020201220201228pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-12/2020-12-18-pulsar-weekly',
        name: '2020-2020-12-2020-12-18-pulsar-weekly',
        component: weekly202020201220201218pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-12/2020-12-11-pulsar-weekly',
        name: '2020-2020-12-2020-12-11-pulsar-weekly',
        component: weekly202020201220201211pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-12/2020-12-04-pulsar-weekly',
        name: '2020-2020-12-2020-12-04-pulsar-weekly',
        component: weekly202020201220201204pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-11/2020-11-27-pulsar-weekly',
        name: '2020-2020-11-2020-11-27-pulsar-weekly',
        component: weekly202020201120201127pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-11/2020-11-20-pulsar-weekly',
        name: '2020-2020-11-2020-11-20-pulsar-weekly',
        component: weekly202020201120201120pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-11/2020-11-13-pulsar-weekly',
        name: '2020-2020-11-2020-11-13-pulsar-weekly',
        component: weekly202020201120201113pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-11/2020-11-06-pulsar-weekly',
        name: '2020-2020-11-2020-11-06-pulsar-weekly',
        component: weekly202020201120201106pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-10/2020-10-30-pulsar-weekly',
        name: '2020-2020-10-2020-10-30-pulsar-weekly',
        component: weekly202020201020201030pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-10/2020-10-23-pulsar-weekly',
        name: '2020-2020-10-2020-10-23-pulsar-weekly',
        component: weekly202020201020201023pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-10/2020-10-16-pulsar-weekly',
        name: '2020-2020-10-2020-10-16-pulsar-weekly',
        component: weekly202020201020201016pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-10/2020-10-09-pulsar-weekly',
        name: '2020-2020-10-2020-10-09-pulsar-weekly',
        component: weekly202020201020201009pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-09/2020-09-25-pulsar-weekly',
        name: '2020-2020-09-2020-09-25-pulsar-weekly',
        component: weekly202020200920200925pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-09/2020-09-18-pulsar-weekly',
        name: '2020-2020-09-2020-09-18-pulsar-weekly',
        component: weekly202020200920200918pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-09/2020-09-11-pulsar-weekly',
        name: '2020-2020-09-2020-09-11-pulsar-weekly',
        component: weekly202020200920200911pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-09/2020-09-04-pulsar-weekly',
        name: '2020-2020-09-2020-09-04-pulsar-weekly',
        component: weekly202020200920200904pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-08/2020-08-28-pulsar-weekly',
        name: '2020-2020-08-2020-08-28-pulsar-weekly',
        component: weekly202020200820200828pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-08/2020-08-21-pulsar-weekly',
        name: '2020-2020-08-2020-08-21-pulsar-weekly',
        component: weekly202020200820200821pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-08/2020-08-14-pulsar-weekly',
        name: '2020-2020-08-2020-08-14-pulsar-weekly',
        component: weekly202020200820200814pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-08/2020-08-07-pulsar-weekly',
        name: '2020-2020-08-2020-08-07-pulsar-weekly',
        component: weekly202020200820200807pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-07/2020-07-31-pulsar-weekly',
        name: '2020-2020-07-2020-07-31-pulsar-weekly',
        component: weekly202020200720200731pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-07/2020-07-24-pulsar-weekly',
        name: '2020-2020-07-2020-07-24-pulsar-weekly',
        component: weekly202020200720200724pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-07/2020-07-17-pulsar-weekly',
        name: '2020-2020-07-2020-07-17-pulsar-weekly',
        component: weekly202020200720200717pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-07/2020-07-10-pulsar-weekly',
        name: '2020-2020-07-2020-07-10-pulsar-weekly',
        component: weekly202020200720200710pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-07/2020-07-03-pulsar-weekly',
        name: '2020-2020-07-2020-07-03-pulsar-weekly',
        component: weekly202020200720200703pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-06/2020-06-26-pulsar-weekly',
        name: '2020-2020-06-2020-06-26-pulsar-weekly',
        component: weekly202020200620200626pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-04/2020-04-17-pulsar-weekly',
        name: '2020-2020-04-2020-04-17-pulsar-weekly',
        component: weekly202020200420200417pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-04/2020-04-03-pulsar-weekly',
        name: '2020-2020-04-2020-04-03-pulsar-weekly',
        component: weekly202020200420200403pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-03/2020-03-06-pulsar-weekly',
        name: '2020-2020-03-2020-03-06-pulsar-weekly',
        component: weekly202020200320200306pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-02/2020-02-28-pulsar-weekly',
        name: '2020-2020-02-2020-02-28-pulsar-weekly',
        component: weekly202020200220200228pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-02/2020-02-14-pulsar-weekly',
        name: '2020-2020-02-2020-02-14-pulsar-weekly',
        component: weekly202020200220200214pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-01/2020-01-31-pulsar-weekly',
        name: '2020-2020-01-2020-01-31-pulsar-weekly',
        component: weekly202020200120200131pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-01/2020-01-17-pulsar-weekly',
        name: '2020-2020-01-2020-01-17-pulsar-weekly',
        component: weekly202020200120200117pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-01/2020-01-10-pulsar-weekly',
        name: '2020-2020-01-2020-01-10-pulsar-weekly',
        component: weekly202020200120200110pulsarweekly
    },
    
    {
        path: '/weekly/2020/2020-01/2020-01-03-pulsar-weekly',
        name: '2020-2020-01-2020-01-03-pulsar-weekly',
        component: weekly202020200120200103pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-12/2019-12-20-pulsar-weekly',
        name: '2019-2019-12-2019-12-20-pulsar-weekly',
        component: weekly201920191220191220pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-12/2019-12-06-pulsar-weekly',
        name: '2019-2019-12-2019-12-06-pulsar-weekly',
        component: weekly201920191220191206pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-11/2019-11-29-pulsar-weekly',
        name: '2019-2019-11-2019-11-29-pulsar-weekly',
        component: weekly201920191120191129pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-11/2019-11-22-pulsar-weekly',
        name: '2019-2019-11-2019-11-22-pulsar-weekly',
        component: weekly201920191120191122pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-11/2019-11-16-pulsar-weekly',
        name: '2019-2019-11-2019-11-16-pulsar-weekly',
        component: weekly201920191120191116pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-11/2019-11-08-pulsar-weekly',
        name: '2019-2019-11-2019-11-08-pulsar-weekly',
        component: weekly201920191120191108pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-11/2019-11-01-pulsar-weekly',
        name: '2019-2019-11-2019-11-01-pulsar-weekly',
        component: weekly201920191120191101pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-10/2019-10-25-pulsar-weekly',
        name: '2019-2019-10-2019-10-25-pulsar-weekly',
        component: weekly201920191020191025pulsarweekly
    },
    
    {
        path: '/weekly/2019/2019-10/2019-10-18-pulsar-weekly',
        name: '2019-2019-10-2019-10-18-pulsar-weekly',
        component: weekly201920191020191018pulsarweekly
    },
    
]

export default routes
