import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import weeklyRoutes from './weeklyRoutes'
import weeklyRedirectRoutes from './weeklyRedirectRoutes'
import notFound from '../views/notFound.vue'

Vue.use(VueRouter)
Vue.use(Meta)

var routes = [
  {
    path: '/404',
    name: 'notFound',
    component: notFound
  }
]
routes = routes.concat(weeklyRoutes)
routes = routes.concat(weeklyRedirectRoutes)

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        return {
          selector: to.hash
        }
      }
    }
});
  
router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) { 
        from.name ? next({
        name: from.name
        }) : next('/404'); 
    } else {
        next();
    }
});

export default router