<template>
    <div class="document-box">
        <div class="container">
                <div class="left-siderbar sticky-siderbar" :style="{ height: leftNavHeight + 'px' }">
                    <div class="weekly-box">
                        <h2>{{ siderBarTitle }}</h2>
                        <div class="version-tree">
                            <el-tree
                                :data="navData"
                                :props="defaultProps"
                                highlight-current
                                show-checkbox
                                node-key="id"
                                :default-expanded-keys="defaultExpandKeys"
                                :default-checked-keys="defaultCheckedKey"
                                ref="tree"
                                @node-click="handleNodeClick">
                                <span slot-scope="{ data, node }">
                                    <span :class="{
                                        'tree-node-first': (node.level == '1'),
                                        'tree-node-second': (node.level == '2' && navData.path == ''),
                                        'left-two-node-active': (data.active)}">{{ node.label }}</span>
                                </span>
                            </el-tree>
                        </div>
                    </div>
                </div>
                <div class="content-container">
                    <markdown-sn class="container-main md-body" :content="content" :options="options"/>
                </div>
        </div>
    </div>
</template>

<script>
import navData from '@/data/weekly/side-bar.json';
import content from '@/weekly/2021/2021-08/2021-08-16-pulsar-weekly';
import MarkdownSn from '@/components/markdown-sn'


export default {
    name: "weekly",
    components: {
        MarkdownSn
    },
    metaInfo: {
        title: 'Pulsar Community Weekly Update - 2021-08-09 ~ 2021-08-15 | StreamNative',
        meta: [{                 // set meta
            name: 'description',
            content: 'This is the Pulsar community weekly update for 2021-08-09 ~ 2021-08-15, with updates on Pulsar client, broker, transactions, and so on.'
        }, {
            property: 'og:title',
            content: 'Pulsar Community Weekly Update - 2021-08-09 ~ 2021-08-15 | StreamNative'
        }, {
            property: 'og:description',
            content: 'This is the Pulsar community weekly update for 2021-08-09 ~ 2021-08-15, with updates on Pulsar client, broker, transactions, and so on.'
        }, {
            property: 'og:url',
            content: 'https://streamnative.io/weekly/2021/2021-08/2021-08-16-pulsar-weekly'
        }, {
            name: 'twitter:title',
            content: 'Pulsar Community Weekly Update - 2021-08-09 ~ 2021-08-15 | StreamNative'
        }, {
            name: 'twitter:description',
            content: 'This is the Pulsar community weekly update for 2021-08-09 ~ 2021-08-15, with updates on Pulsar client, broker, transactions, and so on.'
        }]
    },
    data: function() {
        return {
            type: 'weekly',
            navData: navData.data,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            // readMd: md.render(text),
            leftNavHeight: 0,
            content: content,
            options: {
                markdownIt: {
                    linkify: true,
                    html: true
                },
                linkAttributes: {
                    pattern: /^http/,
                    attrs: {
                    target: '_blank',
                    rel: 'noopener'
                    }
                }
            },
            defaultExpandKeys: [],
            defaultCheckedKey: []
        }
    },

    computed: {
      siderBarTitle() {
            let title = ''
            if (this.type == 'weekly') {
                title = 'weekly'
            }
            if (this.type == 'monthly') {
                title = 'Pulsar 月报'
            }
            if (this.type == 'releases') {
              title = '版本说明'
            }
            return title
      }
    },

    methods: {
        handleNodeClick(data) {
            // this.setNodeActive(navData.data, data.key)
            if (data.key.indexOf('.md') >= 0) {
                window.location = data.path
            }
        },
        getIdByPath(data, path, id) {
            for (var i = 0; i < data.length; i++) {
                if (data[i]['path'] === path) {
                    return data[i]['id']
                }
                if (data[i].hasOwnProperty('children')) {
                    id = this.getIdByPath(data[i]['children'], path, id)
                }
            }
            return id
        },
        getIdByKey(data, key, id) {
             for (var i = 0; i < data.length; i++) {
                if (data[i]['key'] === key) {
                    return data[i]['id']
                }
                if (data[i].hasOwnProperty('children')) {
                    id = this.getIdByKey(data[i]['children'], key, id)
                }
            }
            return id
        },
        setNodeActive(data, key) {
            for (var i = 0; i < data.length; i++) {
                if (data[i]['key'] === key) {
                    if ((data)[i]['path'] !== '') {
                        data[i].active = true
                    } else {
                        data[i].active = false
                    }
                } else {
                    data[i].active = false
                }
                if (data[i].hasOwnProperty('children')) {
                    this.setNodeActive(data[i]['children'], key)
                }
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.leftNavHeight = window.innerHeight + 125;
            var pathList = this.$route.path.split('/')
            var length = 0
            var route = ''
            if (this.$route.path.endsWith('/')) {
                length = pathList.length - 1
                route = this.$route.path.substring(0, this.$route.path.length - 1)
            } else {
                length = pathList.length
                route = this.$route.path
            }
            var key = '';
            var fullIdList = []
            for (var j = 3; j < length; j++) {
                key += '/' + pathList[j]
                if (j == length - 1) {
                    key += '.md'
                }
                fullIdList.push(this.getIdByKey(navData.data, key, -1))
            }
            this.defaultExpandKeys = fullIdList
            var id = this.getIdByPath(navData.data, route, -1)
            this.$refs.tree.setCurrentKey(id)
            this.defaultCheckedKey = [id]
            this.setNodeActive(navData.data, key)
        });
    },
    updated() {
        
    },
};
</script>

<style scoped>

.sticky-siderbar {
  position: -webkit-sticky;
  position: sticky;
  top: 125px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 20px 0
}
.document-box {
    margin-top: 125px;
}
.weekly-box {
    margin-top: 45px;
}
.document-box .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}
.document-box .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap
}

.document-box .left-siderbar {
  min-height: calc(100vh - 250px)
}

.document-box .content-container {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: calc(100% - 35rem);
}

.document-box .left-siderbar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 11.5rem;
  flex: 0 0 20rem;
  border-right: 1px solid #ccc;
}

.document-box .right-siderbar {
  max-width: 240px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12.5rem;
  flex: 0 0 12.5rem;
  border-left: 1px solid #ccc;
}
.document-box .content-container {
  min-height: calc(100vh - 350px)
}
.content-container h1 {
    font-size: 22px;
}
.container-main {
    padding: 15px;
}
.left-siderbar {
    padding: 30px 40px;
    box-sizing: border-box;
    height: 100%;
    /* background: #EDF0F4; */
    overflow-y: scroll;
    border-right: 1px solid #ccc;
}
</style>

<style>

.el-checkbox__inner {
    display: none !important;
}

.center-col{
    height:100%;
}

.left-siderbar .el-tree-node__content {
    position: relative;
}

.left-siderbar .el-tree-node__content>.el-tree-node__expand-icon {
    position: absolute;
    right: 0;
}

.left-siderbar .el-tree-node {
    padding: 5px 0;
}

.left-siderbar .el-tree > .el-tree-node.is-focusable {
    border-bottom: 1px dashed #A9ABB8;
}

.left-siderbar .el-tree > .el-tree-node.is-expanded {
    border-bottom: none;
}

.tree-node-first {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    
}
.tree-node-second {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}

.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    border-radius: 100px;
}

</style>


<style scoped>
    @media screen and (max-width: 1280px) {
        .document-box .container {
            flex-direction: column;
        }

        .document-box .left-siderbar {
            width: 100%;
            min-height: calc(100vh - 350px);
        }

        .document-box .content-container {
            max-width: 100%;
        }

        .sticky-siderbar {
            position: static;
        }

        .weekly-box {
            margin: 0;
        }
    }
</style>