<template>
  <markdown-it-vue ref="markdown" :content="content" :options="options" />
</template>

<script>
/**

 This component is used to make markdown-it-vue support multi-tab parsing.
 The following is usage and a example of multi-tab md content writing.

 Usage:

 ::: tabs
 @@@ tab_name_1
 tab content, it could be any md content
 @@@
 @@@ tab_name_2
 tab content, it could be any md content
 @@@
 :::

 Example:

 # Header 1
 ## Header 2
 ### Header 3

 ::: tabs

 @@@ 代码
 ```c
 #include <stdio.h>
 int main(int argc char* argv[]) {
   printf("Hello, World!");
   return 0;
 }
 ```
 @@@

 @@@ 表格
 | First Header  | Second Header |
 | ------------- | ------------- |
 | Content Cell  | Content Cell  |
 | Content Cell  | Content Cell  |
 @@@

 @@@ 问答
 #### Where are you from?
 I come from China.
 @@@

 :::

 #### Header 4
 ##### Header 5
 ###### Header 6

 */
import Vue from "vue";
import { debounce, merge } from "lodash";
import MarkdownItVue from "markdown-it-vue";
import MarkdownItContainer from "markdown-it-container";
import "markdown-it-vue/dist/markdown-it-vue.css";
import Clipboard from "clipboard";

const defaultOptions = {
  iconStyle: "font-size: 21px; opacity: 0.4;",
  iconClass: "el-icon-document-copy",
  buttonStyle:
    "position: absolute; top: 7.5px; right: 6px; cursor: pointer; outline: none;",
  buttonClass: "",
};

function renderCode(origRule, options) {
  options = merge(defaultOptions, options);
  return (...args) => {
    const [tokens, idx] = args;
    const content = tokens[idx].content
      .replaceAll('"', "&quot;")
      .replaceAll("'", "&lt;");
    const origRendered = origRule(...args);

    if (content.length === 0) return origRendered;

    return `
<div style="position: relative">
	${origRendered}
	<button class="markdown-it-code-copy ${options.buttonClass}" data-clipboard-text="${content}" style="${options.buttonStyle}" title="Copy">
        <i class="${options.iconClass}"></i>
	</button>
</div>
`;
  };
}

const MarkdownItCodeCopy = (md, options) => {
  md.renderer.rules.code_block = renderCode(
    md.renderer.rules.code_block,
    options
  );
  md.renderer.rules.fence = renderCode(md.renderer.rules.fence, options);
};

export default {
  name: "markdown-sn",
  components: {
    MarkdownItVue,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    let _this = this;
    this.$refs.markdown.$data.md
      .use(MarkdownItCodeCopy)
      .use(MarkdownItContainer, "tabs", {
        marker: ":",
        validate: function(params) {
          return params.trim().match(/^tabs\s*(.*)$/);
        },
        render: function(tokens, idx) {
          _this._parseMdTabs();
          if (tokens[idx].nesting === 1) {
            return '<div class="md-sn-tabs"><el-tabs type="card">\n';
          } else {
            return "</div></el-tabs>\n";
          }
        },
      })
      .use(MarkdownItContainer, "tab", {
        marker: "@",
        validate: function(params) {
          return params.trim().match(/^\s*(.*)$/);
        },
        render: function(tokens, idx) {
          if (tokens[idx].nesting === 1) {
            return '<el-tab-pane label="' + tokens[idx].info + '">\n';
          } else {
            return "</el-tab-pane>\n";
          }
        },
      });
    new Clipboard(".markdown-it-code-copy");
  },
  methods: {
    _parseMdTabs: debounce(
      function() {
        this.$nextTick(() => {
          this.parseMdTabs();
        });
      },
      500,
      {
        leading: true,
        trailing: false,
      }
    ),
    parseMdTabs() {
      const doms = document.getElementsByClassName("md-sn-tabs");
      for (const dom of doms) {
        const TmpTabs = Vue.extend({
          template: `${dom.innerHTML}`,
        });
        const component = new TmpTabs().$mount();
        while (dom.hasChildNodes()) {
          dom.removeChild(dom.firstChild);
        }
        dom.appendChild(component.$el);
      }
    },
  },
};
</script>
