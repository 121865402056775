
import weeklyredirect202220220320220314pulsarweekly from '../views/weekly/redirect/2022/2022-03/2022-03-14-pulsar-weekly'

const routes = [
    
    {
        path: '/',
        name: 'redirect2022-2022-03-2022-03-14-pulsar-weekly',
        component: weeklyredirect202220220320220314pulsarweekly
    },
    
    {
        path: '/weekly',
        name: 'redirect2022-2022-03-2022-03-14-pulsar-weekly',
        component: weeklyredirect202220220320220314pulsarweekly
    },
    
]

export default routes
