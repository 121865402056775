<template>
    <div class="footer-box">
        <div class="footer-content">
            <el-row>
                <el-col :span="12" :offset="6">
                    <img class="footer-logo" src="../assets/streamnative_logo.png" alt="">
                    <img class="footer-hippa" src="../assets/img/HIPPA.png" alt="">
                </el-col>
                <el-col :span="12" :offset="6">
                    <ul class="footer-list">
                        <li 
                            v-for="item in footerList.product" 
                            :key="item.key">
                            <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                            <a v-if="item.routerLink" :href="item.routerLink">{{ item.title }}</a>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="12" :offset="6">
                    <ul class="footer-list">
                        <li 
                            v-for="item in footerList.project" 
                            :key="item.key">
                            <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                            <a v-if="item.routerLink" :href="item.routerLink" :target=" item.key === 'bookkeeper' ? '_blank' : '' ">{{ item.title }}</a>
                        </li>
                    </ul>
                </el-col>

                <el-col :span="12" :offset="6">
                    <ul class="footer-list">
                        <li 
                            v-for="item in footerList.developer" 
                            :key="item.key">
                            <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                            <a v-if="item.routerLink" :href="item.routerLink" :target=" item.key === 'pulsar-summit' ? '_blank' : '' ">{{ item.title }}</a>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="12" :offset="6">
                    <ul class="footer-list">
                        <li 
                            v-for="item in footerList.about" 
                            :key="item.key">
                            <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                            <a v-if="item.routerLink" :href="item.routerLink">{{ item.title }}</a>
                        </li>
                    </ul>
                </el-col>

                <el-col :span="12" :offset="6">
                    <ul class="footer-list">
                        <li 
                            v-for="item in footerList.connect" 
                            :key="item.key">
                            <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                            <a v-if="item.routerLink" :href="item.routerLink" target="_blank">{{ item.title }}</a>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="12" :offset="6">
                    <ul class="footer-list">
                        <li>
                            <el-dropdown trigger="click" @command="handleCommandLanguage">
                                <span class="el-dropdown-link">
                                    {{ indexLang }}<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in languageList" :key="item.command" :command="item.command">
                                        <span class="language-icon">
                                            <img :src="item.icon" alt="">
                                            {{ item.label }}
                                        </span>
                                    </el-dropdown-item>

                                </el-dropdown-menu>
                            </el-dropdown>
                        </li>
                    </ul>
                </el-col>
            </el-row>
            <div class="stay-update">
                <div class="hubspot-code">
                    <div class="footeHubspot">
                        <span ref="footerHubspot"></span>
                    </div>
                </div>
            </div>
            <div class="statement-box" v-html="footerList.copyRight">
            </div>
        </div>
    </div>
</template>

<script>
import footerEnList from '@/data/en/footer/footer.json'
import footerZhList from '@/data/zh/footer/footer.json'
export default {
    name: "footerBox",
    data() {
        return {
            footerList: footerEnList,
            languageList: [
                {
                    command: "en",
                    label: "English",
                    icon: "/static/images/header/en.svg"
                },
                {
                    command: "zh",
                    label: "简体中文",
                    icon: "/static/images/header/zh.svg"
                }
            ],
            indexLang: 'English',
        }
    },
    mounted() {
        this.initHubspotScript(this.addHubspotCode)
    },
    created() {
        let currentPath = this.$route.path.split('/')
        let lang = 'en'
        if (currentPath.length >= 2
            && (currentPath[1] === 'zh' || currentPath[1] === 'en')) {
            sessionStorage.setItem('lang', currentPath[1])
            lang = currentPath[1]
        }
        if (this.$lang === 'zh' || lang === 'zh') {
            this.footerList = footerZhList
            this.indexLang = '简体中文'
        } else {
            this.footerList = footerEnList
            this.indexLang = 'English'
        }
    },
    methods: {
        initHubspotScript(callback) {
            let scriptTagHubspotJs = document.createElement('script')
            scriptTagHubspotJs.setAttribute('src', '//js.hsforms.net/forms/shell.js')
            scriptTagHubspotJs.setAttribute('type', 'text/javascript')
            scriptTagHubspotJs.setAttribute('charset', 'utf-8')
            scriptTagHubspotJs.onload = function(){
                callback();
            };
            document.head.appendChild(scriptTagHubspotJs)
            this.$refs.footerHubspot.insertAdjacentElement('beforeend', scriptTagHubspotJs)
        },
        addHubspotCode() {
            let hubspotCode = document.createElement('script')  
            hubspotCode.setAttribute('type', 'text/javascript')
            hubspotCode.setAttribute('defer', 'defer')
            let inlineScript = document.createTextNode('hbspt.forms.create({portalId: "6585952",formId: "5f649319-6bb9-4b52-963a-a9070bc42e77"});')
            hubspotCode.appendChild(inlineScript)
            this.$refs.footerHubspot.insertAdjacentElement('afterend', hubspotCode)
        },
        handleCommandLanguage(data) {
            this.indexLang = this.languageList.find(ele => ele.command === data).label
            let pathList = this.$route.path.split('/')
            sessionStorage.setItem('lang', data)
            if (this.$route.path.indexOf('/platform') < 0) {
                if (pathList.length > 2) {
                   if (this.$route.path.indexOf('/en/') >= 0 && data == 'zh') {
                        window.location.href = this.$route.path.replace('/en/', '/zh/')
                    } else if (this.$route.path.indexOf('/en/') >= 0 && data == 'en') {
                        return
                    } else if (this.$route.path.indexOf('/zh/') >= 0 && data == 'en')  {
                        window.location.href = this.$route.path.replace('/zh/', '/en/')
                    } else if (this.$route.path.indexOf('/zh/') >= 0 && data == 'zh') {
                        return
                    } else {
                         window.location.href = '/' + data + this.$route.path
                    }
                } else {
                    if (data === 'zh') {
                        window.location.href = '/zh/cloud/stable/overview'
                    } else {
                        this.$router.go(0)
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
    .footer-content ul li{
        list-style: none;
    }
    .footer-content {
        width: 100%;
        background: #0d0e28;
        padding: 30px 60px;
        box-sizing: border-box;
        text-align: center;
    }
    .footer-logo {
        width: 170px;
    }
    .footer-hippa {
        width: 100px;
        margin-top: 30px;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }
    .footer-list {
        margin: 20px 0;
    }
    .footer-list a {
        text-align: left;
        color: #656687;
        font-size: 16px;
        line-height: 32px;
        font-family: ff-real-text-pro;
    }
    .stay-update {
        width: 100%;
    }
    
    .statement-box {
        margin-top: 10px;
    }
    .statement-box a{
        color: #656687;
        font-size: 16px;
        line-height: 32px;
        font-family: ff-real-text-pro;
    }
    .statement-box::before, .statement-box::after {
        content: '';
        clear: both;
        display: block;
        zoom: 1;
    }
    .statement-box span {
        margin-right: 20px;
        line-height: 40px;
        font-family: ff-real-text-pro;
    }
    .statement-text {
        float: right;
        color: #b5b5c6;
        font-size: 12px;
        line-height: 40px;
        font-family: ff-real-text-pro;
    }
    .footer-title {
        font-size: 16px;
        font-family: ff-real-text-pro;
        font-weight: bold;
        line-height: 45px;
        color:rgba(168,168,201,1);
        opacity:1;
    }
</style>